<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else-if="!isNotFound">
		<div class="page-header">
			<CRow>
				<CCol md="12">
					<h2 class="typo-h4 d-flex align-items-center">
						{{ info.name }}
						<CBadge
							:color="info.periodStatus.color"
							class="badge-status"
							data-test-id="badge-order-status"
						>
							{{ info.periodStatus.title }}
						</CBadge>
					</h2>
				</CCol>
			</CRow>
			<CRow class="summary">
				<CCol md="12">
					<p class="typo-body-2 m-0 p-0">
						Last updated by: <span class="color-hypertext">{{ updatedBy }}</span><span class="date color-black-45">{{ info.updatedAt }}</span>
					</p>
					<p class="typo-body-2 m-0">
						Created by: <span class="color-hypertext">{{ createdBy }}</span><span class="date color-black-45">{{ info.createdAt }}</span>
					</p>
				</CCol>
			</CRow>
		</div>
		<div class="tabs-container-large mt-4 mb-5 pb-5">
			<div v-if="steps" class="tabs-wrapper">
				<CTabs
					:active-tab="activeTab"
					@update:activeTab="handleUpdateTab"
				>
					<CTab
						v-for="(step, key) in steps"
						:key="key"
						:title="`Step ${key}: ${step.title}`"
						:disabled="step.disabled"
					>
						<div class="main-wrapper">
							<component
								:is="step.component"
								:key="step.title"
								:default-data="step.formData"
								:promotion-type="promotionType"
								is-edit-mode
								@update:is-valid="handleValidChange(key, $event)"
							/>
						</div>
					</CTab>
				</CTabs>
			</div>
			<div class="navigation-panel">
				<div class="main-wrapper">
					<CRow>
						<CCol
							lg="12"
							class="d-flex justify-content-end align-items-center"
						>
							<a
								href="#"
								class="btn-remove mr-auto"
								@click.prevent="handleRemove"
							>
								Remove promotion
							</a>
							<CButton
								color="tertiary"
								class="transparent mr-3"
								@click.prevent="$router.push({ name: 'PromotionList'})"
							>
								Cancel
							</CButton>
							<CButton
								type="button"
								class="transparent"
								color="primary"
								data-test-id="button"
								:disabled="!isEnableSaveChange || edit.isUpdating"
								@click="handleClickSaveChange"
							>
								{{ edit.isUpdating ? 'Saving' : 'Save changes' }}
							</CButton>
						</CCol>
					</CRow>
				</div>
			</div>
		</div>
		<BaseModalConfirm
			ref="modal-confirm"
			:is-submitting="edit.isUpdating"
			title="Save confirmation?"
			description="This promotion is ongoing. Making changes will effect on storefront immediately. Are you sure you want to save?"
			primary-button-text="Confirm save"
			primary-button-loading-text="Saving"
			@onConfirm="handleConfirmSave"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deletePromotion.bind(null, id)"
			:description="confirmDeleteText"
			title="Remove this promotion?"
			is-confirm-required
			@onSuccess="$router.push({ name: 'PromotionList'})"
		/>
	</div>
	<Error404 v-else />
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapState, mapGetters, mapActions } from 'vuex';
import PromotionStepGeneralInfo from '@/components/PromotionStepGeneralInfo.vue';
import PromotionStepCondition from '@/components/PromotionStepCondition.vue';
import PromotionStepReward from '@/components/PromotionStepReward.vue';
import PromotionStepDisplaying from '@/components/PromotionStepDisplaying.vue';
import PromotionStepECoupon from '@/components/PromotionStepECoupon.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import Error404 from '@/components/Error404.vue';

import { DEFAULT_SYSTEM_ADMIN_NAME } from '../enums/general';
import { PROMOTION_LABEL_TITLE_TABS } from '../enums/promotionLabels';
import { PERIOD_STATUSES, PROMOTION_TYPES, PROMOTION_TYPE_LABELS } from '../enums/promotions';
import {
	transformedPromotionInfoToDataMultipleStep,
	transformedPromotionDataToAPI,
} from '../assets/js/transform/promotions';
import { getECouponCodePattern } from '../assets/js/helpers';

export default {
	name: 'PromotionEdit',

	components: {
		BaseModalConfirm,
		Error404,
	},

	data() {
		return {
			id: Number(this.$route.params.id) || null,
			promotionType: '',
			currentStep: 1,
			totalStep: 0,
			steps: null,
			isLoading: true,
		};
	},

	computed: {
		...mapState('promotions', {
			edit: 'edit',
		}),

		...mapGetters({
			info: 'promotions/getPromotionGeneralInfo',
		}),

		updatedBy() {
			if (this.edit && this.edit.data && this.edit.data.updated_by) {
				return this.edit.data.updated_by.username;
			}

			return DEFAULT_SYSTEM_ADMIN_NAME;
		},

		createdBy() {
			if (this.edit && this.edit.data && this.edit.data.created_by) {
				return this.edit.data.created_by.username;
			}

			return DEFAULT_SYSTEM_ADMIN_NAME;
		},

		activeTab() {
			return this.currentStep - 1;
		},

		isEnableSaveChange() {
			// Can not save if expired
			if (this.isPeriodStatusExpired) {
				return false;
			}

			let isAllValid = true;
			for (let iStep = 1; iStep <= this.totalStep; iStep++) {
				isAllValid = isAllValid && this.steps[iStep].valid;
			}

			return isAllValid;
		},

		isTypeBundle() {
			return this.promotionType === PROMOTION_TYPES.BUNDLE;
		},

		isTypeTier() {
			return this.promotionType === PROMOTION_TYPES.TIER;
		},

		isTypeECoupon() {
			return this.promotionType === PROMOTION_TYPES.COUPON;
		},

		periodStatus() {
			const { period_status: periodStatus } = this.edit?.data || {};
			return periodStatus;
		},

		isPeriodStatusSchedule() {
			return this.periodStatus === PERIOD_STATUSES.SCHEDULE;
		},

		isPeriodStatusOngoing() {
			return this.periodStatus === PERIOD_STATUSES.ON_GOING;
		},

		isPeriodStatusExpired() {
			return this.periodStatus === PERIOD_STATUSES.EXPIRED;
		},

		confirmDeleteText() {
			return this.isPeriodStatusOngoing
				? 'This promotion is ongoing. Removing this promotion will effect on storefront immediately. Do you still want to remove this promotion?'
				: 'Are you sure you want to delete this promotion?';
		},

		isNotFound() {
			return isEmpty(this.edit) || isEmpty(this.edit.data);
		},
	},

	async created() {
		await this.getPromotion(this.id);

		this.promotionType = (this.info.type?.value || PROMOTION_TYPES.GENERAL).toUpperCase(); // Force uppercase on query parameter
		this.setPageTitle({
			title: `Edit ${PROMOTION_TYPE_LABELS[this.promotionType]} promotion`,
			isShow: true,
		});

		this.initialStep();

		await this.extractDataToStep();
	},

	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			getPromotion: 'promotions/getPromotion',
			updatePromotion: 'promotions/updatePromotion',
			deletePromotion: 'promotions/deletePromotion',
		}),

		initialStep() {
			if (this.promotionType === PROMOTION_TYPES.COUPON) {
				this.steps = {
					1: {
						title: PROMOTION_LABEL_TITLE_TABS.GENERAL_INFO,
						valid: true,
						disabled: false,
						component: PromotionStepGeneralInfo,
						formData: null,
					},
					2: {
						title: PROMOTION_LABEL_TITLE_TABS.CONDITION,
						valid: true,
						disabled: false,
						component: PromotionStepCondition,
						formData: null,
					},
					3: {
						title: PROMOTION_LABEL_TITLE_TABS.COUPON,
						valid: true,
						disabled: false,
						component: PromotionStepECoupon,
						formData: null,
					},
					4: {
						title: PROMOTION_LABEL_TITLE_TABS.REWARD,
						valid: true,
						disabled: false,
						component: PromotionStepReward,
						formData: null,
					},
					5: {
						title: PROMOTION_LABEL_TITLE_TABS.DISPLAYING,
						valid: true,
						disabled: false,
						component: PromotionStepDisplaying,
						formData: null,
					},
				};
			} else {
				this.steps = {
					1: {
						title: PROMOTION_LABEL_TITLE_TABS.GENERAL_INFO,
						valid: true,
						disabled: false,
						component: PromotionStepGeneralInfo,
						formData: null,
					},
					2: {
						title: PROMOTION_LABEL_TITLE_TABS.CONDITION,
						valid: true,
						disabled: false,
						component: PromotionStepCondition,
						formData: null,
					},
					3: {
						title: PROMOTION_LABEL_TITLE_TABS.REWARD,
						valid: true,
						disabled: false,
						component: PromotionStepReward,
						formData: null,
					},
					4: {
						title: PROMOTION_LABEL_TITLE_TABS.DISPLAYING,
						valid: true,
						disabled: false,
						component: PromotionStepDisplaying,
						formData: null,
					},
				};
			}

			this.totalStep = Object.keys(this.steps).length;
		},

		handleRemove() {
			this.$refs['modal-remove'].open();
		},

		async handleClickSaveChange() {
			// Don't save anymore
			if (this.isPeriodStatusExpired) {
				return;
			}

			// Direclty save
			if (this.isPeriodStatusSchedule) {
				await this.submitForm();
				return;
			}

			// Need confirmation
			if (this.isPeriodStatusOngoing) {
				this.$refs['modal-confirm'].open();
			}
		},

		async handleConfirmSave() {
			await this.submitForm();
		},

		handleUpdateTab(tabIndex) {
			this.currentStep = tabIndex + 1;
		},

		handleValidChange(key, value) {
			if (!key) {
				return;
			}
			const { valid, data: formData } = value;

			this.$set(this.steps, key, { ...this.steps[key], valid, formData });

			const nextIndex = Number(key) + 1;
			// Check disabled status for the rest
			// Bundle
			// E-Coupon
			// Tier Condition
			for (let index = nextIndex; index <= this.totalStep; index++) {
				// Bundle Promotion
				if (this.isTypeBundle) {
					if (Number(key) === 2) {
						const { productBundleCondition } = this.steps[2].formData;

						this.$set(this.steps, (3), {
							...this.steps[3],
							formData: {
								...this.steps[3].formData,
								productBundleCondition,
							},
						});
					}
				}

				// E-Coupon promotion
				// Use couponCodes to display badge UI
				if (this.isTypeECoupon) {
					if (Number(key) === 3) {
						const {
							prefix = '',
							length = 0,
							couponCodes,
						} = this.steps[3].formData || {};

						const couponQuantity = (couponCodes || []).length;
						const couponCodePattern = getECouponCodePattern(prefix, length, couponCodes);

						this.$set(this.steps, (5), {
							...this.steps[5],
							formData: {
								...this.steps[5].formData,
								couponQuantity,
								couponCodePattern,
							},
						});
					}
				}

				// Tier Promotion
				if (this.isTypeTier) {
					if (Number(key) === 2) {
						const { tierCondition } = this.steps[2].formData;
						const { tierCondition: tierConditionNextStep } = this.steps[index].formData;

						const mergeTierConditions = tierCondition.map((cond, condIndex) => {
							if (tierConditionNextStep && tierConditionNextStep[condIndex]) {
								return {
									...cond,
									...tierConditionNextStep[condIndex],
								};
							}

							return cond;
						});

						this.$set(this.steps, (index), {
							...this.steps[index],
							formData: {
								...this.steps[index].formData,
								tierCondition: mergeTierConditions,
							},
						});
					}
				}
			}
		},

		async submitForm() {
			const postData = transformedPromotionDataToAPI({ type: this.promotionType, steps: this.steps });

			await this.updatePromotion({ id: this.id, params: postData });

			// After submit form clear new generated code
			this.clearNewGeneratedCoupons();
		},

		async extractDataToStep() {
			if (!this.edit.data) { return; }

			const promotionData = await transformedPromotionInfoToDataMultipleStep(this.edit.data);
			if (promotionData) {
				this.steps[1].formData = promotionData.steps[1].formData;
				this.steps[2].formData = promotionData.steps[2].formData;
				this.steps[3].formData = promotionData.steps[3].formData;
				this.steps[4].formData = promotionData.steps[4].formData;
				if (this.steps[5]) {
					const {
						prefix = '',
						length = 0,
						couponCodes = [],
					} = promotionData.steps[3].formData;
					const { formData } = promotionData.steps[5];

					const couponQuantity = (couponCodes || []).length;
					const couponCodePattern = getECouponCodePattern(prefix, length, couponCodes);

					this.steps[5].formData = {
						...formData,
						couponQuantity,
						couponCodePattern,
					};
				}
			}
			this.isLoading = false;
		},

		clearNewGeneratedCoupons() {
			if (this.isTypeECoupon) {
				const newCouponCodes = this.steps[3].formData.newCouponCodes;
				if (newCouponCodes) {
					this.steps[3].formData.newCouponCodes = [];
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.page-header {
		margin: 0 rem(-28);
		padding: 0 rem(28);
	}

	.summary {
		.date {
			&::before {
				content: "|";
				display: inline-block;
				margin-left: rem(5);
				margin-right: rem(5);
			}
		}
	}

	.badge-status {
		width: auto;
		padding: rem(7) rem(8);
		margin-left: rem(16);
		font-size: rem(14);
	}

	.tabs-container-large {
		.tabs-wrapper {
			::v-deep & > div > div:first-child {
				margin-top: 0;
				padding-top: rem(32);
			}
		}
	}

	.navigation-panel {
		position: fixed;
		left: rem(56);
		right: 0;
		bottom: 0;
		padding: rem(12) 0;
		box-shadow: 0 1px 2px 0 $color-gray-07, 0 4px 16px 0 $color-gray-15;
		background-color: $color-white;
		z-index: 9;

		@media (max-width: 991.98px) {
			left: 0;
		}

		::v-deep button {
			min-width: rem(80);
		}
	}
</style>
